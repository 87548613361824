'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { ComponentProps, FC } from 'react';
import { Button } from '../Button/Button';
import tw from 'twin.macro';

export type GoDownButtonProps = ComponentProps<'a'>;

export const GoDownButton: FC<GoDownButtonProps> = ({ ...rest }) => {
  return (
    <a
      css={[
        tw`min-h-[4rem] min-w-[4rem] rounded-full border-2 border-black bg-transparent flex items-center justify-center`,
      ]}
      {...rest}
    >
      <ChevronDownIcon tw="w-8 h-8" />
    </a>
  );
};
