import React, { ComponentProps, FC, PropsWithChildren, useState } from 'react';
import { Button } from '../Button/Button';

type ModalProps = PropsWithChildren<{
  modalId?: string;
  buttonText?: string | JSX.Element;
}> &
  ComponentProps<typeof Button>;

export const Modal: FC<ModalProps> = ({
  modalId = 'my_modal_2',
  buttonText = 'Open modal',
  children,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const onShowModal = () => {
    // @ts-expect-error added by daisy ui
    if (document) document?.getElementById(modalId)?.showModal();
    setShowModal(true);
  };

  const onHideModal = () => {
    // @ts-expect-error added by daisy ui
    if (document) document?.getElementById(modalId)?.close();
    setShowModal(false);
  };

  return (
    <>
      <Button className="btn" onClick={onShowModal} {...rest}>
        {buttonText}
      </Button>
      <dialog id={modalId} className="modal" open={showModal}>
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={onHideModal}
            >
              ✕
            </button>
          </form>
          {showModal && children}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={onHideModal}>close</button>
        </form>
      </dialog>
    </>
  );
};
