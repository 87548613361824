/** @jsxImportSource @emotion/react */

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PolymorphicComponentProps } from '@sal-solution/types';
import { ElementType, PropsWithChildren, ReactElement } from 'react';
import tw from 'twin.macro';

const BASE_BUTTON = tw`btn`;

const BUTTON_VARIANTS = {
  primary: tw`btn-primary text-white`,
  secondary: tw`btn-secondary text-white`,
  accent: tw`btn-accent text-white`,
  ghost: tw`btn-ghost text-black`,
  danger: tw`btn-error bg-red-500 text-white`,
  warning: tw`bg-yellow-500 text-white`,
  success: tw`bg-green-500 text-white`,
  info: tw`bg-blue-500 text-white`,
  light: tw`bg-gray-200 text-black`,
  dark: tw`bg-gray-800 text-white`,
  link: tw`btn-link`,
  blank: tw``,
  round: tw`btn-primary text-white rounded-full`,
  'round-outlined': tw`min-h-[4rem] min-w-[4rem] rounded-full border-2 border-black bg-transparent`,
  tab: tw`tab hover:(border-r-primary border-r-8)`,
  'tab-active': tw`tab-active border-r-8 border-r-primary hover:(border-r-primary border-r-8)`,
};

export type ButtonProps<C extends ElementType> = PolymorphicComponentProps<
  C,
  PropsWithChildren<{
    disabled?: boolean;
    variant?: keyof typeof BUTTON_VARIANTS;
  }>
>;

export function Button<C extends ElementType>({
  variant = 'primary',
  children,
  as,
  ...props
}: ButtonProps<C>): ReactElement {
  const Component = as ?? 'button';

  return (
    <Component css={[BASE_BUTTON, BUTTON_VARIANTS[variant]]} {...props}>
      {children}
    </Component>
  );
}
