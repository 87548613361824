import React, { FC } from 'react';

type SelectItem = {
  title: string;
  value?: string;
  action?: () => void;
};

type SelectProps = {
  defaultItem: string;
  selectItems: SelectItem[];
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const Select: FC<SelectProps> = ({
  defaultItem,
  selectItems,
  name,
  onChange,
}) => {
  return (
    <select className="select max-w-xs" onChange={onChange} name={name}>
      <option disabled selected>
        {defaultItem}
      </option>
      {selectItems.map((item, index) => (
        <option value={item?.value || item.title} key={index}>
          {item.title}
        </option>
      ))}
    </select>
  );
};
