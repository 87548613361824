'use client';
import { FC, useEffect } from 'react';

export type FuttyProps = {
  futtyKey: string;
};

export const Futty: FC<FuttyProps> = ({ futtyKey }) => {
  useEffect(() => {
    if (!window || !document) return;
    // @ts-expect-error flutty implementation
    window.Futy = { key: `${futtyKey}` };
    (function (e, t) {
      // @ts-expect-error flutty implementation
      var n = e.createElement(t);
      // @ts-expect-error flutty implementation
      n.async = true;
      // @ts-expect-error flutty implementation
      var f = window.Promise && window.fetch ? 'modern.js' : 'legacy.js';
      // @ts-expect-error flutty implementation
      n.src = 'https://v1.widget.futy.io/js/futy-widget-' + f;
      // @ts-expect-error flutty implementation
      var r = e.getElementsByTagName(t)[0];
      // @ts-expect-error flutty implementation
      r.parentNode.insertBefore(n, r);
    })(document, 'script');
  }, []);

  return <></>;
};
