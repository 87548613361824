import { ChangeEvent, FC, useState } from 'react';
import 'twin.macro';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Text } from '../../Text/Text';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';
import { recordToArray } from '../util/object';

const componentName = 'ResumeExperiences';

type ResumeExperiencesProps = Record<string, string>;
type StoredProps = {
  title: string;
  company: string;
  date: string;
  description: string;
  technologies: string;
};

export const ResumeExperiences: FC = () => {
  const { storage, isEditing } =
    useResumeComponentStorage<ResumeExperiencesProps>(componentName);

  if (isEditing) {
    return <Editor storage={storage} />;
  }

  const data = recordToArray<StoredProps>(storage);

  return (
    <div>
      <Text variant="title">My Experiences</Text>
      <ul>
        {data.map((exp, index) => (
          <li tw="flex flex-col" key={index}>
            <Text variant="title" tw="text-xl">
              {exp.title}
            </Text>
            <Text variant="sub" tw="text-base">
              {exp.company}
            </Text>
            <Text variant="sub" tw="text-base">
              {exp.date}
            </Text>
            <Text tw="my-2">{exp.description}</Text>
            <Text tw="font-bold">Tech used:</Text>
            <ul tw="grid grid-rows-3 grid-flow-col gap-y-2 gap-x-4 w-fit">
              {exp.technologies?.split(' ').map((tech, index) => (
                <Text as="li" tw="flex-1" key={index}>
                  {tech}
                </Text>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Editor: FC<{ storage?: ResumeExperiencesProps }> = ({ storage }) => {
  const [experiences, setExperiences] = useState(
    recordToArray<StoredProps>(storage) || []
  );

  const addExperience = () => {
    setExperiences([
      ...experiences,
      { title: '', company: '', date: '', description: '', technologies: '' },
    ]);
  };

  const removeExperience = (index: number) => {
    const newExperiences = [...experiences];
    newExperiences.splice(index, 1);
    setExperiences(newExperiences);
  };

  const updateExperience = (
    index: number,
    key: keyof (typeof experiences)[number],
    value: string
  ) => {
    const newExperiences = [...experiences];
    newExperiences[index][key] = value;
    setExperiences(newExperiences);
  };

  return (
    <form name={componentName} tw="w-full">
      {experiences.map((exp, index) => (
        <div key={index} tw="space-y-4">
          <Input
            name={`title-${index}`}
            placeholder="Title"
            value={exp.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              updateExperience(index, 'title', e.target.value)
            }
          />
          <Input
            name={`company-${index}`}
            placeholder="Company"
            value={exp.company}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              updateExperience(index, 'company', e.target.value)
            }
          />
          <Input
            name={`date-${index}`}
            placeholder="Date"
            value={exp.date}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              updateExperience(index, 'date', e.target.value)
            }
          />
          <Text
            tw="w-full rounded-xl border-2 border-gray-300 p-2 min-h-[300px]"
            name={`description-${index}`}
            placeholder="Description"
            as="textarea"
            value={exp.description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              updateExperience(index, 'description', e.target.value)
            }
          />
          <Input
            name={`technologies-${index}`}
            placeholder="Technologies"
            value={exp.technologies}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              updateExperience(index, 'technologies', e.target.value)
            }
          />
          <Button type="button" onClick={() => removeExperience(index)}>
            Remove
          </Button>
        </div>
      ))}
      <Button type="button" onClick={addExperience}>
        Add Experience
      </Button>
    </form>
  );
};
