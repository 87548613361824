/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { FC, PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { Text } from '../Text/Text';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
const mask = tw`
before:(
  [content: ""] 
  absolute
  h-3
  w-3
  [inset-inline-start: 99.9%]
  [mask-size: contain]
  [mask-position: center]
  [mask-repeat: no-repeat]
  [mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMycgaGVpZ2h0PSczJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9J2JsYWNrJyBkPSdtIDAgMyBMIDEgMyBMIDMgMyBDIDIgMyAwIDEgMCAwJy8+PC9zdmc+)]
)
`;
export type ChatBubbleProps = {
  isSender?: boolean;
  sender?: string;
  icon?: string | JSX.Element;
  iconIsImage?: boolean;
  iconIsRound?: boolean;
  timeStamp?: string;
  hasError?: boolean;
  errorMessage?: string
};

export const ChatBubble: FC<PropsWithChildren<ChatBubbleProps>> = ({
  isSender = true,
  hasError = false,
  sender = 'Agent',
  timeStamp = '12:00',
  icon,
  iconIsImage = false,
  iconIsRound = true,
  errorMessage,
  children,
}) => {
  return (
    <div
      css={[
        mask,
        isSender
          ? tw`ml-auto rounded-md rounded-br-none bg-green-600 before:(bottom-0 right-0 bg-green-600)`
          : tw`mr-auto rounded-md rounded-tl-none bg-slate-800 before:(top-0 -left-3 bg-slate-800 rotate-180)`,
      ]}
      tw="relative p-4 lg:min-w-[400px] max-w-full backdrop-blur-md  flex items-center text-white space-x-4"
    >
      {icon && (
        <div tw="w-10">
          {iconIsImage ? (
            <img
              css={[
                iconIsRound
                  ? tw`rounded-full object-cover`
                  : tw`rounded-md bg-white px-2 py-1`,
              ]}
              src={icon as string}
              alt="Renting agent"
              width={40}
              height={40}
            />
          ) : (
            icon
          )}
        </div>
      )}
      <div tw="w-full">
        <div tw="flex justify-between w-full gap-4">
          <Text tw="font-bold flex items-center">
            {hasError && (
              <ExclamationCircleIcon tw="w-8 h-8 mr-2 text-red-500 bg-white rounded-full" />
            )}
            {sender}
          </Text>
          <Text>{timeStamp}</Text>
        </div>

        {children}
      </div>
    </div>
  );
};
