/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { FC } from 'react';
import tw, { TwComponent } from 'twin.macro';
import { Text } from '../Text/Text';

type StepWithHighlightProps = {
  countText: string;
  title: string;
  description: string;
  highlightedText: string;
  SpanColor: TwComponent<'span'>;
};

export const highLightColors = [
  tw.span`before:(bg-yellow-200) bg-yellow-200`,
  tw.span`before:(bg-blue-200) bg-blue-200`,
  tw.span`before:(bg-green-200) bg-green-200`,
  tw.span`before:(bg-pink-200) bg-pink-200`,
  tw.span`before:(bg-purple-200) bg-purple-200`,
];

export const pickRandomColor = () => {
  return highLightColors[Math.floor(Math.random() * highLightColors.length)];
};

export const StepWithHighlight: FC<StepWithHighlightProps> = ({
  countText,
  title,
  description,
  highlightedText,
  SpanColor,
}) => {
  return (
    <div>
      <Text tw="font-bold">
        <SpanColor tw="relative text-black mr-4 before:(absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-8 h-8 rounded-full rotate-6)">
          <span tw="relative z-10 text-3xl">{countText}</span>
        </SpanColor>
        <span tw="text-2xl">{title}</span>
      </Text>
      <Text>{hightLightText(description, highlightedText, SpanColor)}</Text>
    </div>
  );
};

export const hightLightText = (
  text: string,
  highlight: string,
  SpanColor: TwComponent<'span'>
) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <SpanColor key={index}>{part}</SpanColor>
    ) : (
      part
    )
  );
};
