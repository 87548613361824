import React, { FC, useEffect, useState } from 'react';
import 'twin.macro';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';

const componentName = 'ResumePicture';

type ResumePictureProps = {
  profileImage?: string;
};

export const ResumePicture: FC = () => {
  const { storage, isEditing } =
    useResumeComponentStorage<ResumePictureProps>(componentName);

  if (isEditing) {
    return <ResumePictureEditor storage={storage} />;
  }
  return (
    <div>
      <ImageAsCanvas
        src={storage?.profileImage || 'https://via.placeholder.com/150'}
      />
    </div>
  );
};

const ImageAsCanvas: FC<{ src: string }> = ({ src }) => {
  const draw = (img: string) => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (!canvas) return;

    // const bitmap = JSON.parse(img) as ImageBitmap;

    // canvas.width = bitmap.width;
    // canvas.height = bitmap.height;
    // const ctx = canvas.getContext('2d');
    // ctx?.drawImage(bitmap, 0, 0);
  };

  useEffect(() => {
    if (src) {
      draw(src);
    }
  }, [src]);

  return (
    <canvas id="canvas" tw="w-36 h-36 rounded-full absolute top-4 right-4" />
  );
};

export const ResumePictureEditor: FC<{ storage?: ResumePictureProps }> = ({
  storage,
}) => {
  const [src, setSrc] = useState<string>(storage?.profileImage || '');

  const storeImageSrc = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const bitmap = await createImageBitmap(file);
      draw(bitmap);
      // const url = URL.createObjectURL(file);
      //const img = new Image();
      // img.onload = () => draw(img);
      //  img.src = url;
      setSrc(JSON.stringify(bitmap));
    }
  };

  function draw(img: ImageBitmap) {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (!canvas) return;

    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0);
  }

  return (
    <div tw="pt-4">
      <input type="file" accept="image/*" onChange={storeImageSrc} />
      <form name={componentName}>
        <canvas
          id="canvas"
          tw="w-36 h-36 rounded-full absolute top-4 right-4"
        />
        <input name="profileImage" type="input" hidden value={src} />
      </form>
    </div>
  );
};
