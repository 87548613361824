'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import React, { FC, useEffect, useRef } from 'react';
import tw from 'twin.macro';

const Container = tw.div`relative flex overflow-hidden w-full`;
const SlidesContainer = tw.div`flex w-full h-full transition-transform ease-linear py-2`;
const Slide = tw.div`flex-none w-[300px] px-4 flex items-center justify-center text-center`;

export type SliderProps = {
  slides: (string | JSX.Element)[]; // Assuming these could be images or any content
  speed?: number; // Adjust speed for your needs
};

export const Slider: FC<SliderProps> = ({ slides, speed = 8, ...rest }) => {
  const slidesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // @ts-expect-error it exists
    const slideWidth = slidesRef.current?.firstChild?.clientWidth;
    const moveSlide = () => {
      if (slidesRef.current) {
        slidesRef.current.style.transition = `transform ${speed}s linear`;
        slidesRef.current.style.transform = `translateX(-${slideWidth}px)`;

        const transitionEnd = () => {
          slidesRef.current!.appendChild(slidesRef.current!.firstChild!);
          slidesRef.current!.style.transition = 'none';
          slidesRef.current!.style.transform = 'translateX(0)';
          slidesRef.current!.removeEventListener(
            'transitionend',
            transitionEnd
          );
        };

        slidesRef.current.addEventListener('transitionend', transitionEnd);
      }
    };

    const interval = setInterval(moveSlide, 0); // Adjust time for your needs

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <Container {...rest}>
      <SlidesContainer ref={slidesRef}>
        {slides.concat(slides).map(
          (
            slide,
            index // Duplicate slides for a seamless transition
          ) => (
            <Slide key={index}>{slide}</Slide>
          )
        )}
      </SlidesContainer>
    </Container>
  );
};
