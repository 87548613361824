import React, { FC, ReactElement } from 'react';
import { Button } from '../Button/Button';
import tw from 'twin.macro';

type TabProps = {
  name: string;
  label: string;
  content: ReactElement;
};

type TabBarProps = {
  tabs: TabProps[];
  pageTab?: string;
  useLink?: boolean;
  LinkComponent?: React.ElementType;
  reactRouterLink?: React.ElementType;
};

export const TabBar: FC<TabBarProps> = ({
  tabs,
  useLink,
  LinkComponent,
  reactRouterLink,
  pageTab,
}) => {
  const tabNames = tabs?.map((tab) => tab.name) || [];
  const currentTab = tabNames?.includes(window.location.search)
    ? window.location.search
    : tabNames[0];

  const [activeTab, setActiveTab] = React.useState(currentTab);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  React.useEffect(() => {
    // check if query has param activeTab
    const activeTab = pageTab;
    if (activeTab) {
      if (tabs.find((tab) => tab.name === activeTab)) setActiveTab(activeTab);
    }
  }, [pageTab, tabs]);

  const as = useLink ? reactRouterLink || LinkComponent || 'button' : 'button';

  return (
    <div tw="grid [grid-template-columns: 300px 1fr] w-full h-full space-x-4 p-4">
      <aside>
        <nav tw="flex flex-col space-y-4">
          {tabs?.map((tab, i) => (
            <Button
              key={i}
              variant={activeTab === tab.name ? 'tab-active' : 'tab'}
              type={useLink ? undefined : 'button'}
              as={as}
              onClick={useLink ? undefined : () => handleTabClick(tab.name)}
              href={
                useLink && LinkComponent ? createTabUrl(tab.name) : undefined
              }
              to={
                useLink && reactRouterLink ? createTabUrl(tab.name) : undefined
              }
            >
              {tab.label}
            </Button>
          ))}
        </nav>
      </aside>
      {tabs?.map((tab) => (
        <div
          key={tab.name}
          css={[
            tab.name === activeTab
              ? tw`flex w-full h-full dark:bg-slate-700 rounded p-4 flex-col space-y-4 max-w-screen-sm`
              : tw`hidden`,
          ]}
        >
          {tab.content}
        </div>
      ))}
    </div>
  );
};

const createTabUrl = (tab: string) => {
  const path = `${window.location.pathname}?tab=${tab}`;
  return path;
};
