'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';
import { ChangeEvent, ComponentProps, FC, useState } from 'react';
import tw from 'twin.macro';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { shortenString } from '@sal-solution/utils';
import { Input } from '../Input/Input';

type FileUploadProps = {
  uploadText: string;
  showFileNames?: boolean;
} & ComponentProps<typeof Input>;

export const FileUpload: FC<FileUploadProps> = ({
  uploadText = 'Upload file',
  showFileNames,
  ...props
}) => {
  const [imagesSrc, setImagesSrc] = useState<string[]>();
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputEvent = event as unknown as ChangeEvent<HTMLInputElement>;
    if (inputEvent?.target?.files) {
      const imageSrcs = Array.from(inputEvent?.target?.files).map(
        (file) => file.name
      );
      setImagesSrc(imageSrcs);
    }
  };
  return (
    <div tw="relative w-full">
      <Button tw="w-full" variant="info">
        <ArrowUpTrayIcon tw="w-4 h-4" />
        {uploadText} {imagesSrc ? `(${imagesSrc.length})` : ''}
      </Button>
      {showFileNames && imagesSrc && imagesSrc.length > 0 && (
        <Text>
          {imagesSrc.map((src) => shortenString(src, 100)).join(', ')}
        </Text>
      )}
      <input
        css={[tw`opacity-0 absolute inset-0 w-full h-full cursor-pointer`]}
        {...props}
        onChange={onInputChange}
        type="file"
        accept="image/*"
        multiple
      />
    </div>
  );
};
