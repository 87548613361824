export const generateInstagramAuthUrl = (
  clientId: string,
  redirectUri: string
) => {
  const baseUrl = 'https://api.instagram.com/oauth/authorize';
  const params = {
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: 'user_profile',
    response_type: 'code',
  } as const;

  const paramsString = Object.keys(params) as (keyof typeof params)[];
  const queryString = paramsString
    .map((key: keyof typeof params) => `${key}=${params[key]}`)
    .join('&');
  return `${baseUrl}?${queryString}`;
};

export const randomString = (length: number) => {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
  if (!length) {
    length = Math.floor(Math.random() * chars.length);
  }
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
};

// offerte-comments?offerId=6675b8f8100202bf08cd5c20&contractorId=66673780a24a6ef43ffe47d5&responseId=667a8ca323f432fe36f93f16&source=contractor
export const createOfferChatUrl = (
  offerId: string,
  responseId: string,
  source: string,
  contractorId?: string
): string => {
  const baseUrl = `${process.env['APP_URL']}/offerte-comments`;

  const paramsString = [];

  if (offerId) {
    paramsString.push(`offerId=${offerId}`);
  }

  if (responseId) {
    paramsString.push(`responseId=${responseId}`);
  }

  if (contractorId && source === 'contractor') {
    paramsString.push(`contractorId=${contractorId}`);
  }
  if (source) {
    paramsString.push(`source=${source}`);
  }

  // const paramsString = Object.keys(params) as (keyof typeof params)[];
  const queryString = paramsString.join('&');
  return `${baseUrl}?${queryString}`;
};

// type=response&offerId=6675b8f8100202bf08cd5c20&to=66673780a24a6ef43ffe47d5&location=Amsterdam&date=Geen%20voorkeur&amountOfCabinets=14&wrapTargets=Keuken%20kasten&expirationDate=2024-06-24T08:39:54.447Z
export const createContractorReplyUrl = (
  type: string,
  offerId: string,
  to: string,
  location: string,
  date: string,
  amountOfCabinets: string,
  wrapTargets: string,
  expirationDate: string
): string => {
  const baseUrl = `${process.env['APP_URL']}/offer`;
  const params = {
    type,
    offerId,
    to,
    location,
    date,
    amountOfCabinets,
    wrapTargets,
    expirationDate,
  } as const;

  const paramsString = Object.keys(params) as (keyof typeof params)[];
  const queryString = paramsString
    .map((key: keyof typeof params) => `${key}=${params[key]}`)
    .join('&');
  return `${baseUrl}?${queryString}`;
};

export const makeId = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const shortenString = (str: string, maxLength: number) => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const yesOrNo = (value?: boolean | null | undefined) =>
  value ? 'Yes' : 'No';
