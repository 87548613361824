/** @jsxImportSource @emotion/react */

import { FC, PropsWithChildren } from 'react';
import tw from 'twin.macro';

type FormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
} & React.FormHTMLAttributes<HTMLFormElement>;

const StyledForm = tw.form`flex flex-col`;

export const Form: FC<PropsWithChildren<FormProps>> = ({
  children,
  ...props
}) => {
  return <StyledForm {...props}>{children}</StyledForm>;
};
