export const INTERESTED = 'interested';
export const NOT_INTERESTED = 'Not interested';
export const OFFER_ACCEPTED = 'Offer accepted';
export const NO_RESPONSE = 'No response';
export const WILL_UPLOAD = 'Will upload';
export const WAITING_FOR_RESPONSE = 'Waiting for offers';
export const OFFERS_RECEIVED = 'Offers received';
export const DOUBLE = 'Double';
export const INTERESTED_STATUSES = [INTERESTED, NO_RESPONSE, WILL_UPLOAD];
export const NOT_INTERESTED_STATUSES = [NOT_INTERESTED];
export const FINISHED_STATUSES = [OFFER_ACCEPTED];

export const STATUSES = [
  WAITING_FOR_RESPONSE,
  OFFERS_RECEIVED,
  ...INTERESTED_STATUSES,
  ...NOT_INTERESTED_STATUSES,
  ...FINISHED_STATUSES,
];

export const STATUS_SELECT_ITEMS = [
  { title: INTERESTED },
  { title: NOT_INTERESTED },
  { title: OFFER_ACCEPTED },
  { title: NO_RESPONSE },
  { title: WILL_UPLOAD },
  { title: WAITING_FOR_RESPONSE },
  { title: OFFERS_RECEIVED },
  { title: DOUBLE },
];
