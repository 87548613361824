/** @jsxImportSource @emotion/react */
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import React, { FC, Fragment } from 'react';
import 'twin.macro';
import tw from 'twin.macro';
import { MenuItem } from '../MenuItem/MenuItem';
import { Text } from '../Text/Text';

const Container = tw.div`flex flex-col w-full h-full py-4 space-y-8`;

const VARIANTS = {
  dark: tw`bg-gray-800 text-white`,
  light: tw`bg-gray-200 text-black`,
};

type SidebarProps = {
  title?: string;
  description?: string;
  linkElement?: React.ElementType;
  variant?: keyof typeof VARIANTS;
  menuItems: MenuItem[];
};

export const Sidebar: FC<SidebarProps> = ({
  title,
  description,
  variant = Object.keys(VARIANTS)[0] as keyof typeof VARIANTS,
  menuItems,
  linkElement,
  ...rest
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const Icon = isCollapsed ? ArrowRightCircleIcon : ArrowLeftCircleIcon;
  const variantStyle = VARIANTS[variant];
  return (
    <Container
      tw="hidden lg:(flex overflow-y-scroll)"
      css={[
        variantStyle,
        isCollapsed
          ? tw`max-w-[30px] px-0 overflow-x-hidden`
          : tw`max-w-[170px] px-8`,
      ]}
      {...rest}
    >
      <div tw="relative">
        <Text variant="title" css={[isCollapsed ? tw`hidden` : tw``]}>
          {title}
        </Text>
        <Text variant="sub" css={[isCollapsed ? tw`hidden` : tw``]}>
          {description}
        </Text>
        <Icon
          tw="w-6 h-6 cursor-pointer absolute right-0 top-1/2 -translate-y-1/2"
          onClick={toggleCollapse}
        />
      </div>

      {menuItems?.map((menu, index) => (
        <Fragment key={index}>
          {index !== 0 && index !== menuItems.length && (
            <div className="divider"></div>
          )}
          <MenuItem
            item={menu}
            linkElement={linkElement}
            css={[isCollapsed ? tw`hidden` : tw``]}
          />
        </Fragment>
      ))}
    </Container>
  );
};

export const generateSidebarItems = (count: number): MenuItem[] => {
  return Array.from({ length: count }, (_, index) => ({
    title: `Menu ${index + 1}`,
    items: Array.from({ length: count }, (_, index) => ({
      title: `Item ${index + 1}`,
      url: `/item${index + 1}`,
      count: index === 0 ? 10 : undefined,
      isActive: index === 0,
    })),
  }));
};
