'use client';
/** @jsxImportSource @emotion/react */

import React, { ComponentProps, FC } from 'react';
import { Button } from '../Button/Button';
import logoSrc from './assets/WALogo.png';

export type WhatsAppButtonProps = {
  tel?: string;
  logo?: string;
} & ComponentProps<typeof Button>;

export const WhatsAppButton: FC<WhatsAppButtonProps> = ({
  tel = '+32485448524',
  logo = logoSrc,
  ...props
}) => {
  return (
    <Button as="a" href={`https://wa.me/${tel}`} target="_blank" {...props}>
      <img width={25} height={25} src={logo} alt="whatsApp" /> Vragen? App ons!
    </Button>
  );
};
