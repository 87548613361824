/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { FC } from 'react';
import { Card } from '../Card/Card';
import { Text } from '../Text/Text';
import ReactMarkdown from 'markdown-to-jsx';
import { shortenString } from '@sal-solution/utils';

export type BlogCardProps = {
  title: string;
  content: string;
  createdAt: number;
};

export const BlogCard: FC<BlogCardProps> = ({
  title = 'New blog post',
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo',
  createdAt = Date.now(),
  ...rest
}) => {
  const date = new Date(createdAt);
  const daysAgo = Math.floor(
    (Date.now() - date.getTime()) / (1000 * 60 * 60 * 24)
  );
  const isNew = daysAgo < 2;

  const contentShort = shortenString(content, 100);
  return (
    <Card tw="relative" {...rest}>
      {isNew && (
        <div tw="badge badge-primary badge-sm absolute -top-1 -right-2">
          Nieuw
        </div>
      )}
      <Text variant="subTitle">{title}</Text>
      <ReactMarkdown className="markdown">{contentShort}</ReactMarkdown>
      <Text tw="text-gray-500 text-sm lg:text-sm">
        {date.toLocaleDateString('nl-NL')}{' '}
        {daysAgo > 1 ? `(${daysAgo} dagen geleden)` : '(vandaag)'}
      </Text>
    </Card>
  );
};
