'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { ComponentProps, FC, useState, ChangeEvent } from 'react';
import { Input } from '../Input/Input';
import { Text } from '../Text/Text';
import tw from 'twin.macro';

type InputImagesProps = ComponentProps<typeof Input>;

export const InputImages: FC<InputImagesProps> = ({
  placeholder,
  CustomLabel,
  ...props
}) => {
  const [imagesSrc, setImagesSrc] = useState<string[]>();

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputEvent = event as unknown as ChangeEvent<HTMLInputElement>;
    if (inputEvent?.target?.files) {
      const imageSrcs = Array.from(inputEvent?.target?.files).map((file) =>
        URL.createObjectURL(file)
      );
      setImagesSrc(imageSrcs);
    }
  };

  const LabelText = CustomLabel || Text;
  return (
    <div tw="relative">
      <LabelText variant="subTitle" tw="w-full text-left text-base">
        {props.label}
      </LabelText>
      {imagesSrc ? (
        <div tw="grid grid-cols-2 gap-4">
          {imagesSrc.map((src, index) => (
            <img
              src={src}
              alt={props.name}
              key={index}
              tw="w-full h-40 object-cover"
            />
          ))}
        </div>
      ) : (
        <ImagePlaceHolder placeholder={placeholder} name={props.name} />
      )}
      <input
        css={[tw`opacity-0 absolute inset-0 w-full h-full cursor-pointer`]}
        {...props}
        onChange={onInputChange}
        type="file"
        accept="image/*"
        multiple
      />
    </div>
  );
};

type ImagePlaceHolderProps = {
  placeholder?: string;
  name?: string;
};

const ImagePlaceHolder: FC<ImagePlaceHolderProps> = ({ placeholder, name }) => {
  return (
    <Text
      as="label"
      htmlFor={name}
      tw="border-dashed rounded-lg border-2 border-gray-300 w-full h-40 flex items-center justify-center cursor-pointer [user-select: none] [word-break: break-all] p-4"
    >
      {placeholder}
    </Text>
  );
};
