'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { FC, useState } from 'react';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { Text } from '../Text/Text';

const createEmptyArray = (length: number) =>
  Array.from({ length }, () => {
    return {
      placeholder: 'Enter text',
      name: 'name',
      label: 'Name',
      defaultValue: '',
    };
  });

export type InputMultiProps = {
  name: string;
  label: string;
  placeholder: string;
  defaultValues?: string[];
  required?: boolean;
};

export const InputMulti: FC<InputMultiProps> = ({
  name = 'input-multi',
  label = 'Input Multi',
  placeholder = 'Enter text',
  defaultValues,
  required,
}) => {
  const [amount, setAmount] = useState(
    defaultValues?.map((value) => ({
      placeholder,
      name,
      label,
      defaultValue: value,
    })) || createEmptyArray(4)
  );

  const increase = () => {
    if (amount.length > 0)
      setAmount((prev) => [...prev, createEmptyArray(1)[0]]);
  };
  const decrease = () => {
    if (amount.length > 0) setAmount((prev) => prev.slice(0, -1));
  };

  return (
    <div tw="flex flex-col space-y-4">
      {amount.map((input, key) => (
        <Input
          CustomLabel={({ children }) => (
            <Text variant="subTitle" tw="w-full text-left text-base">
              {children}
            </Text>
          )}
          defaultValue={input.defaultValue}
          name={name}
          label={`${label} ${key + 1}`}
          placeholder={placeholder}
          key={key}
          required={required}
        />
      ))}
      <div tw="w-full flex justify-center space-x-4">
        <Button tw="h-12 w-12" variant="round" type="button" onClick={decrease}>
          <MinusIcon tw="w-8 h-8" />
        </Button>
        <Button tw="h-12 w-12" variant="round" type="button" onClick={increase}>
          <PlusIcon tw="w-8 h-8" />
        </Button>
      </div>
    </div>
  );
};
