'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { FC, PropsWithChildren } from 'react';
import { Button } from '../Button/Button';
import { Form } from '../Form/Form';
import { Input } from '../Input/Input';
import { InputImages } from '../InputImages/InputImages';
import { FileUpload } from '../FileUpload/FileUpload';
import { Text } from '../Text/Text';

export type UploadUserInformationProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  locale?: 'en-US' | 'nl-NL';
  isLoading?: boolean;
};

export const UploadUserInformation: FC<
  PropsWithChildren<UploadUserInformationProps>
> = ({ onSubmit, locale = 'nl-NL', isLoading, children }) => {
  const copy = locale === 'en-US' ? copyEN : copyNL;
  return (
    <Form tw="grid grid-cols-2 gap-4" onSubmit={onSubmit}>
      <Input label={copy.name} name="name" />
      <Input label={copy.city} name="town" />
      <Input label={copy.email} name="email" />
      <Input label={copy.phoneNumber} name="phone" />
      <div tw="col-span-2">{children}</div>
      <div tw="col-span-2 lg:col-span-1">
        <FileUpload
          uploadText={copy.uploadImages}
          name="images"
          placeholder={copy.imagesPlaceholder}
        />
      </div>
      <Button
        tw="col-span-2 lg:col-span-1"
        variant="success"
        disabled={isLoading}
      >
        {copy.submit}
      </Button>
    </Form>
  );
};

const copyEN = {
  name: 'Name',
  city: 'City',
  email: 'Email',
  phoneNumber: 'Phone number',
  images: 'Upload images of your kitchen (optional)',
  imagesPlaceholder: 'Upload your images',
  submit: 'Submit',
  uploadImages: 'Upload images',
};

const copyNL = {
  name: 'Naam',
  city: 'Woonplaats',
  email: 'Email',
  phoneNumber: 'Telefoonnummer',
  images: 'Upload afbeeldingen van je keuken (optioneel)',
  imagesPlaceholder: 'Upload je afbeeldingen',
  submit: 'Verstuur',
  uploadImages: 'Upload afbeeldingen',
};
