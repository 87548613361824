/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { FC } from 'react';
import { Section } from '../Layout/Layout';
import { Text } from '../Text/Text';

export const SectionDivider: FC<{ title: string; id?: string }> = ({
  title,
  ...rest
}) => (
  <Section
    tw="p-0 flex flex-col w-full border-opacity-50 py-0 lg:py-0 2xl:py-0"
    {...rest}
  >
    <div tw="divider">
      <Text as="h1" variant="title" tw="text-lg lg:text-2xl">
        {title}
      </Text>
    </div>
  </Section>
);
