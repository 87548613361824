'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import {
  ComponentProps,
  FC,
  useRef,
  RefObject,
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import { Input } from '../Input/Input';
import { Text } from '../Text/Text';
import tw from 'twin.macro';

type InputImageProps = ComponentProps<typeof Input>;

export const InputImage: FC<InputImageProps> = ({
  placeholder,
  CustomLabel,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const imageRef: RefObject<HTMLInputElement> = useRef(null);
  const imageRefCurrent = imageRef.current;
  useEffect(() => {
    if (imageRefCurrent) {
      imageRefCurrent.addEventListener('change', (event: Event) => {
        const inputEvent = event as unknown as ChangeEvent<HTMLInputElement>;
        if (inputEvent?.target?.files?.[0]) {
          console.log('image selected', inputEvent?.target?.files?.[0]);
          setImageSrc(URL.createObjectURL(inputEvent?.target?.files?.[0]));
        }
      });
    } else {
      console.log('no image ref');
    }

    return () => {
      const currentImageRef = imageRefCurrent;
      if (currentImageRef) {
        currentImageRef.removeEventListener('change', (event) => {
          console.log('image selected', event?.target);
        });
      }
    };
  }, [imageRefCurrent]);
  const LabelText = CustomLabel || Text;
  return (
    <div tw="relative">
      <LabelText variant="subTitle" tw="w-full text-left">
        {props.label}
      </LabelText>
      {imageSrc ? (
        <img src={imageSrc} alt={props.name} tw="w-full h-40 object-cover" />
      ) : (
        <ImagePlaceHolder placeholder={placeholder} name={props.name} />
      )}
      <input
        css={[tw`opacity-0 absolute inset-0 w-full h-full cursor-pointer`]}
        ref={imageRef}
        {...props}
        type="file"
        accept="image/*"
      />
    </div>
  );
};

type ImagePlaceHolderProps = {
  placeholder?: string;
  name?: string;
};

const ImagePlaceHolder: FC<ImagePlaceHolderProps> = ({ placeholder, name }) => {
  return (
    <Text
      as="label"
      htmlFor={name}
      tw="border-dashed rounded-lg border-2 border-gray-300 w-full h-40 flex items-center justify-center cursor-pointer [user-select: none] [word-break: break-all] p-4"
    >
      {placeholder}
    </Text>
  );
};
