import React, { FC, PropsWithChildren } from 'react';

type DropdownItem = {
  title: string;
  action?: () => void;
};

type DropdownProps = {
  dropdownItems: DropdownItem[];
};

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  dropdownItems,
  children,
}) => {
  return (
    <details className="dropdown">
      <summary className="btn bg-transparent m-1">{children}</summary>
      <ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
        {dropdownItems.map((item, index) => (
          <li key={index}>
            <button onClick={item.action}>{item.title}</button>
          </li>
        ))}
      </ul>
    </details>
  );
};
