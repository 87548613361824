'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { FC } from 'react';
import tw from 'twin.macro';
import { Button } from '../Button/Button';
import { Carousel } from '../Carousel/Carousel';
import { ContentAndImageLayout } from '../ContentAndImageLayout/ContentAndImageLayout';
import { Text } from '../Text/Text';
import { WhatsAppButton } from '../WhatsAppButton/WhatsAppButton';
export type ProductProps = {
  name?: string;
  price?: number;
  description?: string;
  disclaimer?: string;
  image?: string;
  images?: string[];
  WALogo?: string;
  features?: string[];
  tel?: string;
  onClick?: () => void;
};

const Image = tw.img`w-[200px] h-[200px] rounded-lg shadow-lg`;

export const Product: FC<ProductProps> = ({
  onClick,
  name = '',
  description = '',
  features = [],
  disclaimer = '',
  image,
  images,
  tel,
  WALogo,
}) => {
  return (
    <ContentAndImageLayout
      topContent={
        <div tw="h-full w-full flex justify-center items-end">
          <Image src={image} alt="product" />
        </div>
      }
      bottomContent={
        <div tw="h-full w-full flex flex-col justify-start items-start space-y-4 pt-[15vh] pb-36 text-black">
          <Text tw="w-full text-left" variant="title">
            {name}
          </Text>
          <Text>{description}</Text>
          {features && (
            <ul tw="pl-4">
              {features.map((feature) => (
                <Text as="li" tw="list-disc" key={feature}>
                  {feature}
                </Text>
              ))}
            </ul>
          )}
          <Text>{disclaimer}</Text>
          {images && <Carousel images={images} variant="secondary" />}
          <div tw="mt-auto w-full max-w-screen-md flex flex-col fixed left-1/2 -translate-x-1/2 bottom-0 p-4 bg-gradient-to-t from-white to-transparent">
            <Button onClick={onClick} tw="mb-4">
              Bestel nu
            </Button>
            <WhatsAppButton logo={WALogo} tel={tel} />
          </div>
        </div>
      }
    />
  );
};
