import { ChangeEvent, FC, useState } from 'react';
import 'twin.macro';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Text } from '../../Text/Text';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';
import { recordToArray } from '../util/object';

const componentName = 'ResumeReference';

type ResumeReferenceProps = Record<string, string>;
type StoredProps = { name: string; title: string; reference: string };

export const ResumeReference = () => {
  const { storage, isEditing } =
    useResumeComponentStorage<ResumeReferenceProps>(componentName);

  if (isEditing) {
    return <Editor storage={storage} />;
  }

  const data = recordToArray<StoredProps>(storage);

  return (
    <div>
      <Text variant="title">My References</Text>
      <ul>
        {data.map((ref, index) => (
          <li key={index}>
            <Text variant="title" tw="text-xl">
              {ref.name}
            </Text>
            <Text variant="sub" tw="text-base">
              {ref.title}
            </Text>
            <Text>{ref.reference}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Editor: FC<{ storage?: ResumeReferenceProps }> = ({ storage }) => {
  const [references, setReferences] = useState(
    recordToArray<StoredProps>(storage) || []
  );

  const addReference = () => {
    setReferences([...references, { name: '', title: '', reference: '' }]);
  };

  const removeReference = (index: number) => {
    const newReferences = [...references];
    newReferences.splice(index, 1);
    setReferences(newReferences);
  };

  const updateReference = (
    index: number,
    key: keyof (typeof references)[number],
    value: string
  ) => {
    const newReferences = [...references];
    newReferences[index][key] = value;
    setReferences(newReferences);
  };

  return (
    <form name={componentName} tw="w-full">
      {references.map((ref, index) => (
        <div tw="space-y-2" key={index}>
          <Input
            name={`name-${index}`}
            value={ref.name}
            onChange={(e) => updateReference(index, 'name', e.target.value)}
            placeholder="Name"
          />
          <Input
            name={`title-${index}`}
            value={ref.title}
            onChange={(e) => updateReference(index, 'title', e.target.value)}
            placeholder="Title"
          />
          <Text
            tw="w-full rounded-xl border-2 border-gray-300 p-2"
            as="textarea"
            name={`reference-${index}`}
            value={ref.reference}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              updateReference(index, 'reference', e.target.value)
            }
            placeholder="Reference"
          />
          <Button type="button" onClick={() => removeReference(index)}>
            Remove
          </Button>
        </div>
      ))}
      <Button type="button" onClick={addReference}>
        Add Reference
      </Button>
    </form>
  );
};
