'use client';
import { FC, useEffect, useRef } from 'react';
import { randomString } from '@sal-solution/utils';

export type CountdownProps = {
  from?: string;
  modifier?: string;
};

const idModifier = randomString(5);

export const Countdown: FC<CountdownProps> = ({
  from = 'July 07, 2024 15:37:25',
  modifier = idModifier,
}) => {
  const retryRefs = useRef(0);

  const daysId = `count-down-days-${modifier}`;
  const hoursId = `count-down-hours-${modifier}`;
  const minId = `count-down-min-${modifier}`;
  const secId = `count-down-sec-${modifier}`;

  const countdownFrom = (from: string) => {
    const days = document.getElementById(daysId);
    const hours = document.getElementById(hoursId);
    const min = document.getElementById(minId);
    const sec = document.getElementById(secId);

    if (!days || !hours || !min || !sec) {
      console.error('days', daysId, days);
      console.error('hours', hoursId, hours);
      console.error('min', minId, min);
      console.error('sec', secId, sec);
      console.error('Countdown elements not found');
      if (retryRefs.current < 3) {
        retryRefs.current += 1;
        setTimeout(() => {
          countdownFrom(from);
        }, 1000);
      }
      return;
    }

    const countDownDate = new Date(from).getTime();

    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const d = Math.floor(distance / (1000 * 60 * 60 * 24));
      const h = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const s = Math.floor((distance % (1000 * 60)) / 1000);

      days.style.cssText = `--value: ${d.toString()};`;

      hours.style.cssText = `--value: ${h.toString()};`;

      min.style.cssText = `--value: ${m.toString()};`;

      sec.style.cssText = `--value: ${s.toString()};`;

      if (distance < 0) {
        clearInterval(x);

        days.style.cssText = '--value: 0;';

        hours.style.cssText = '--value: 0;';

        min.style.cssText = '--value: 0;';

        sec.style.cssText = '--value: 0;';
      }
    }, 1000);
  };

  useEffect(() => {
    countdownFrom(from);
  }, []);
  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
      <div className="flex flex-col p-2  bg-amber-100 rounded-md">
        <span className="countdown font-mono text-5xl">
          <span id={daysId} style={{ '--value': 15 }}></span>
        </span>
        days
      </div>
      <div className="flex flex-col p-2  bg-amber-100 rounded-md">
        <span className="countdown font-mono text-5xl">
          <span id={hoursId} style={{ '--value': 10 }}></span>
        </span>
        hours
      </div>
      <div className="flex flex-col p-2  bg-amber-100 rounded-md">
        <span className="countdown font-mono text-5xl">
          <span id={minId} style={{ '--value': 24 }}></span>
        </span>
        min
      </div>
      <div className="flex flex-col p-2 bg-amber-100 rounded-md">
        <span className="countdown font-mono text-5xl">
          <span id={secId} style={{ '--value': 49 }}></span>
        </span>
        sec
      </div>
    </div>
  );
};
