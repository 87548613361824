/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { FC } from 'react';
import { Text } from '../Text/Text';

type StepsProps = {
  step: string;

  description: string;
  explanation: string;
};

export const Step: FC<StepsProps> = ({ step, description, explanation }) => (
  <div tw="text-center">
    <Text
      as="h1"
      variant="title"
      tw=" text-9xl text-gray-300 relative before:(content-[' '] w-20 h-20 bg-blue-50 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2)"
    >
      <span tw="z-10 relative"> {step}</span>
    </Text>
    <Text tw="font-semibold lg:text-xl">{description}</Text>
    <Text tw="text-gray-500">{explanation}</Text>
  </div>
);
