/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import 'twin.macro';
import tw from 'twin.macro';

const VARIANTS = {
  primary: tw``,
  light: tw`text-white  font-bold`,
};

type CheckmarkProps = {
  label: string;
  name: string;
  required?: boolean;
  className?: string;
  defaultChecked?: boolean;
  variant?: keyof typeof VARIANTS;
};

export const Checkmark: FC<CheckmarkProps> = ({
  label,
  name,
  required,
  className,
  defaultChecked,
  variant = 'primary',
}) => {
  const variantStyles = VARIANTS[variant];
  return (
    <div tw="form-control">
      <label tw="cursor-pointer label">
        <span tw="label-text mr-4" css={[variantStyles]}>
          {label}
        </span>
        <input
          required={required}
          name={name}
          type="checkbox"
          className={className}
          defaultChecked={defaultChecked}
          tw=" checkbox-success checkbox text-green-500 checked:([--chkbg: theme('colors.green.500')])"
        />
      </label>
    </div>
  );
};
