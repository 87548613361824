/** @jsxImportSource @emotion/react */

import tw from 'twin.macro';

import { Interpolation, Theme } from '@emotion/react';
import {
  ElementType,
  FC,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { Text } from '../Text/Text';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PolymorphicComponentProps } from '@sal-solution/types';

const Container = tw.div`flex flex-col items-center justify-center w-full relative`;

export const INPUT_BASE = tw`border border-gray-300 px-4 py-2 rounded-xl w-full `;

export const INPUT_VARIANTS = {
  default: tw`border-2 border-gray-300 bg-white text-black`,
  error: tw`border-2 border-red-500`,
  success: tw`border-2 border-green-500`,
  light: tw`bg-transparent text-white`,
};

type InputProps<C extends ElementType> = PolymorphicComponentProps<
  C,
  {
    required?: boolean;
    containerCSS?: Interpolation<Theme>;
    variant?: keyof typeof INPUT_VARIANTS;
    label?: string;
    name?: string;
    placeholder?: string;
    errorMessage?: string;
    successMessage?: string;
    type?: string;
    defaultValue?: string;
    value?: string;
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    disabled?: boolean;
    CustomLabel?: (props: PropsWithChildren) => JSX.Element;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    accept?: InputHTMLAttributes<HTMLInputElement>['accept'];
    inputRef?: React.RefObject<HTMLInputElement>;
  }
>;

export function Input<C extends ElementType>({
  variant = 'default',
  label,
  iconLeft,
  iconRight,
  containerCSS,
  required,
  CustomLabel,
  as,
  ...props
}: InputProps<C>): ReactElement {
  const ICON_LEFT_STYLE = iconLeft ? tw`pl-10` : tw``;
  const ICON_RIGHT_STYLE = iconRight ? tw`pr-10` : tw``;
  const LabelText = (CustomLabel as unknown as typeof Text) || Text;
  const Component = as ?? 'input';
  return (
    <Container css={containerCSS ? containerCSS : []}>
      {label && (
        <LabelText variant="subTitle" tw="w-full text-base text-left pb-2">
          {label} {required && <span tw="text-red-500">*</span>}
        </LabelText>
      )}
      <label tw="sr-only">{props.name}</label>
      {iconLeft && <span tw="absolute left-4 w-4 opacity-50">{iconLeft}</span>}
      <Component
        css={[
          INPUT_BASE,
          INPUT_VARIANTS[variant],
          ICON_LEFT_STYLE,
          ICON_RIGHT_STYLE,
        ]}
        ref={props?.inputRef}
        required={required}
        {...props}
      />
      {iconRight && (
        <span tw="absolute right-4 w-4 opacity-50">{iconRight}</span>
      )}
    </Container>
  );
}
