'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { ComponentProps, FC, PropsWithChildren, useState } from 'react';
import { Form } from '../Form/Form';
import { PolymorphicComponentProps } from '../../types/polymorphic';
import { Stepper } from '../Stepper/Stepper';
import { INPUT_BASE, INPUT_VARIANTS, Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { InputImage } from '../InputImage/InputImage';
import { toast } from 'react-toastify';
import { Radio } from '../Radio/Radio';
import { Text } from '../Text/Text';
import { Checkmark } from '../Checkmark/Checkmark';
import tw from 'twin.macro';
import { InputMulti } from '../InputMulti/InputMulti';
import { InputImages } from '../InputImages/InputImages';
type SmartFormElementTypes =
  | 'input'
  | 'image'
  | 'images'
  | 'date'
  | 'textArea'
  | 'multiple';

export type SmartFormElementCheckMark = {
  label: string;
  type: 'checkmark';
  required?: boolean;
  variants?: string;
  checkMarkVariant?: ComponentProps<typeof Checkmark>['variant'];
  checkMarks: {
    name: string;
    label: string;
    defaultChecked?: boolean;
  }[];
};

export type SmartFormElementBase =
  | {
      name: string;
      label: string;
      placeholder: string;
      type: SmartFormElementTypes;
      required?: boolean;
      value?: string;
      inputType?: string;
      defaultValue?: string;
      defaultValues?: string[];
    }
  | {
      label: string;
      type: 'radio';
      required?: boolean;
      radios: {
        name: string;
        value: string;
        label: string;
        defaultChecked?: boolean;
      }[];
    }
  | SmartFormElementCheckMark;

type SmartFormElementInput = SmartFormElementBase &
  PolymorphicComponentProps<'input'>;

export type SmartFormElement = SmartFormElementInput;

type SmartFormProps = {
  elements: SmartFormElement[][];
  loading?: boolean;
  steps?: string[];
  debug?: boolean;
  submitText?: string;
  nextText?: string;
  extraValidation?: (step: number, errorMessage?: string) => void;
  previousText?: string;
} & ComponentProps<typeof Form>;

export const SmartForm: FC<PropsWithChildren<SmartFormProps>> = ({
  elements,
  steps,
  debug = false,
  loading,
  submitText = 'Submit',
  nextText = 'Next',
  extraValidation,
  previousText = 'Previous',
  children,
  ...props
}) => {
  const [step, setStep] = useState(0);
  const shouldUseStepper = elements.length > 0;
  const stepperSteps = steps || createSteps(elements);

  const onNextStep = () => {
    try {
      if (shouldUseStepper) {
        const inStepElements = elements[step];
        const requiredElements = inStepElements.filter(
          (el) => el.required && el.type !== 'checkmark'
        );
        const requiredElementNames = requiredElements.map((el) => el.name);
        const requiredElementValues = requiredElementNames.map((name) => {
          const input: HTMLInputElement | null = document.querySelector(
            `input[name="${name}"]`
          );
          return input?.value;
        });

        extraValidation && extraValidation(step);

        if (requiredElementValues.some((value) => !value)) {
          const missingFields = requiredElementNames
            .filter((name, i) => !requiredElementValues[i])
            .join(', ');

          throw Error(`Voer a.u.b. alle velden in: ${missingFields}`);
        }
      }
      setStep((prev) => (prev + 1 === stepperSteps.length ? 1 : prev + 1));
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const onPreviousStep = () => {
    setStep((prev) => (prev + 1 === 1 ? stepperSteps.length : prev - 1));
  };

  return (
    <Form {...props} tw="space-y-4">
      {elements.length > 1 && (
        <Stepper steps={stepperSteps} currentStep={step + 1} />
      )}
      {elements.map((el, stepI) =>
        el.map((el, i) => (
          <div
            key={el.name}
            style={
              shouldUseStepper
                ? isInStep(stepI, step)
                  ? { display: 'block' }
                  : { display: 'none' }
                : {}
            }
          >
            {debug && <span>{i}</span>}
            {switchElement(el)}
          </div>
        ))
      )}
      {children}
      <div tw="flex" css={[elements.length > 1 && step !== 0 && tw`space-x-4`]}>
        <Button
          css={[shouldUseStepper && step !== 0 ? tw`` : tw`hidden`]}
          type="button"
          variant="light"
          onClick={onPreviousStep}
        >
          {previousText}
        </Button>

        <Button
          css={[step + 1 !== stepperSteps.length ? tw`` : tw`hidden`]}
          tw="flex-1 lg:(flex-none)"
          type="button"
          onClick={onNextStep}
        >
          {nextText}
        </Button>

        <Button
          css={[step + 1 === stepperSteps.length ? tw`` : tw`hidden`]}
          tw="flex-1 lg:(flex-none)"
          type="submit"
          variant="success"
          loading={loading}
        >
          {loading ? 'Loading...' : submitText}
        </Button>
      </div>
    </Form>
  );
};

const isInStep = (index: number, step: number) => {
  return index === step;
};

const createSteps = (elements: SmartFormElement[][]) => {
  return elements.map((el, i) => ' ');
};

const switchElement = (el: SmartFormElement) => {
  switch (el.type) {
    case 'input':
      return (
        <Input
          CustomLabel={({ children }) => (
            <Text variant="subTitle" tw="w-full text-left text-base">
              {children}
            </Text>
          )}
          required={el.required}
          label={el.label}
          type={el.inputType || el.type}
          name={el.name}
          placeholder={el.placeholder}
          defaultValue={el.defaultValue}
        />
      );
    case 'image':
      return (
        <InputImage
          CustomLabel={({ children }) => (
            <Text variant="subTitle" tw="w-full text-left text-base">
              {children}
            </Text>
          )}
          required={el.required}
          label={el.label}
          type={el.type}
          name={el.name}
          placeholder={el.placeholder}
          defaultValue={el.defaultValue}
        />
      );
    case 'images':
      return (
        <InputImages
          CustomLabel={({ children }) => (
            <Text variant="subTitle" tw="w-full text-left text-base">
              {children}
            </Text>
          )}
          required={el.required}
          label={el.label}
          type={el.type}
          name={el.name}
          placeholder={el.placeholder}
          defaultValue={el.defaultValue}
        />
      );
    case 'radio':
      return (
        <>
          <Text variant="subTitle" tw="w-full text-left text-base">
            {el.label} {el.required && <span tw="text-red-500">*</span>}
          </Text>
          {el.radios.map((radio) => (
            <Radio
              key={radio.value}
              name={el.name as string}
              label={radio.label}
              value={radio.value}
              required={el.required}
              className={`${radio.name}-${el.name}`}
              defaultChecked={radio?.defaultChecked}
            />
          ))}
        </>
      );
    case 'date':
      return (
        <Input
          CustomLabel={({ children }) => (
            <Text variant="subTitle" tw="w-full text-left text-base">
              {children}
            </Text>
          )}
          containerCSS
          required={el.required}
          label={el.label}
          type={el.type}
          name={el.name}
          placeholder={el.placeholder}
          defaultValue={el.defaultValue}
        />
      );

    case 'checkmark':
      return (
        <>
          <Text variant="subTitle" tw="w-full text-left text-base">
            {el.label} {el.required && <span tw="text-red-500">*</span>}
          </Text>
          {el.checkMarks.map((checkMark) => (
            <Checkmark
              key={checkMark.name}
              name={checkMark.name}
              label={checkMark.label}
              // required={el.required}
              defaultChecked={checkMark?.defaultChecked}
              className={`${el.name}`}
              variant={el.checkMarkVariant}
            />
          ))}
        </>
      );
    case 'textArea':
      return (
        <>
          <Text variant="subTitle" tw="w-full text-left text-base">
            {el.label} {el.required && <span tw="text-red-500">*</span>}
          </Text>
          <textarea
            css={[INPUT_BASE, INPUT_VARIANTS.default, tw`min-h-[200px]`]}
            required={el.required}
            name={el.name}
            placeholder={el.placeholder}
            defaultValue={el.defaultValue}
          />
        </>
      );
    case 'multiple':
      return <InputMulti {...el} />;

    default:
      return <p>Type not found</p>;
  }
};
