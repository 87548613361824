import 'twin.macro';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';
import { Text } from '../../Text/Text';
import { ComponentProps, FC, useState } from 'react';
import { Button } from '../../Button/Button';
import { recordToArray } from '../util/object';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';

const cName = 'ResumeList';
type ResumeListProps = Record<string, string>;
type StoredProps = { item: string };
type Props = {
  componentName?: string;
  title?: string;
  titleVariant?: ComponentProps<typeof Text>['variant'];
};

export const ResumeList: FC<Props> = ({
  componentName = cName,
  title = 'List',
  titleVariant = 'title',
}) => {
  const { isEditing, storage } =
    useResumeComponentStorage<ResumeListProps>(componentName);

  if (isEditing) {
    return (
      <Editor
        storage={storage}
        componentName={componentName}
        title={title}
        titleVariant={titleVariant}
      />
    );
  }

  return (
    <div>
      <Text variant={titleVariant}>{title}</Text>
      <ul>
        {recordToArray<StoredProps>(storage).map((edu, index) => (
          <li tw="flex" key={index}>
            <Text>{edu.item}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Editor: FC<{
  storage?: ResumeListProps;
  title?: string;
  titleVariant?: ComponentProps<typeof Text>['variant'];
  componentName: string;
}> = ({ storage, componentName, title, titleVariant }) => {
  const [items, setItems] = useState(recordToArray<StoredProps>(storage) || []);

  const addItem = () => {
    setItems([...items, { item: '' }]);
  };

  const removeItem = (index: number) => {
    const newEducations = [...items];
    newEducations.splice(index, 1);
    setItems(newEducations);
  };

  const updateItem = (
    index: number,
    key: keyof (typeof items)[number],
    value: string
  ) => {
    const newEducations = [...items];
    newEducations[index][key] = value;
    setItems(newEducations);
  };

  return (
    <form name={componentName} tw="w-full">
      <Text variant={titleVariant}>{title}</Text>

      {items.map((edu, index) => (
        <div key={index}>
          <input
            placeholder="Item"
            type="text"
            name={`item-${index}`}
            value={edu.item}
            onChange={(e) => updateItem(index, 'item', e.target.value)}
          />

          <Button type="button" onClick={() => removeItem(index)}>
            <MinusIcon tw="w-4" />
          </Button>
        </div>
      ))}

      <div>
        <Button type="button" onClick={addItem}>
          <PlusIcon tw="w-4" />
        </Button>
      </div>
    </form>
  );
};
