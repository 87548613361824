/** @jsxImportSource @emotion/react */
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import React, { FC, Fragment } from 'react';
import 'twin.macro';
import tw from 'twin.macro';
import { Button } from '../Button/Button';
import { MenuItem } from '../MenuItem/MenuItem';

const BottomNav = tw.div`lg:hidden mt-auto sticky z-20 bottom-0 w-full p-4 border-t border-gray-300 bg-slate-800`;
const SlideMenu = tw.div`lg:hidden w-full h-full fixed top-0 bg-slate-800 p-4 space-y-4 overflow-y-scroll`;

type BottomNavigatorProps = {
  linkElement?: React.ElementType;
  menuItems: MenuItem[];
};

export const BottomNavigator: FC<BottomNavigatorProps> = ({
  menuItems,
  linkElement,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <BottomNav>
      <Button onClick={toggleCollapse}>
        <Bars3Icon tw="w-6 h-6" />
      </Button>
      <SlideMenu
        css={
          isCollapsed ? tw`hidden -right-full ` : tw`flex flex-col right-0 z-10`
        }
      >
        {menuItems?.map((menu, index) => (
          <Fragment key={index}>
            {index !== 0 && index !== menuItems.length && (
              <div className="divider"></div>
            )}
            <MenuItem
              onClick={toggleCollapse}
              item={menu}
              linkElement={linkElement}
              css={[isCollapsed ? tw`hidden` : tw``]}
            />
          </Fragment>
        ))}
        <Button tw="absolute top-4 right-4" onClick={toggleCollapse}>
          <XMarkIcon tw="w-6 h-6" />
        </Button>
      </SlideMenu>
    </BottomNav>
  );
};
