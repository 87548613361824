import { useEffect } from 'react';

export const useIntersectionObserver = ({
  elementId,
  rootId,
  callback,
}: {
  elementId: string;
  rootId?: string;
  callback: IntersectionObserverCallback;
}) => {
  if (!elementId) throw new Error('elementId is required');

  useEffect(() => {
    const root = rootId ? document.getElementById(rootId) : null;
    const observer = new IntersectionObserver(callback, {
      root: root,
      rootMargin: '0px',
      threshold: 0.1,
    });

    const element = document.getElementById(elementId);
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) observer.unobserve(element); // Unobserve specific element
      observer.disconnect();
    };
  }, [elementId, rootId, callback]);
};
