import { FC, useEffect } from 'react';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';
import 'twin.macro';
import { Text } from '../../Text/Text';
import { Input } from '../../Input/Input';
import {
  ResumePicture,
  ResumePictureEditor,
} from '../ResumePicture/ResumePicture';

const componentName = 'ResumePerson';

type ResumePersonProps = Partial<{
  name: string;
  title: string;
}>;

export const ResumePerson = () => {
  const { storage, isEditing } =
    useResumeComponentStorage<ResumePersonProps>(componentName);

  if (isEditing) {
    return <Editor storage={storage} />;
  }
  return (
    <div>
      <ResumePicture />
      <Text variant="title">{storage?.name}</Text>
      <Text variant="sub">{storage?.title}</Text>
    </div>
  );
};

const Editor: FC<{ storage?: ResumePersonProps }> = ({ storage }) => {
  return (
    <form name={componentName} tw="space-y-2">
      <Text
        as={Input}
        variant="title"
        label="Name:"
        placeholder="John doe"
        type="text"
        name="name"
        defaultValue={storage?.name}
      />
      <Text
        as={Input}
        variant="sub"
        label="Title:"
        placeholder="Tech Lead"
        type="text"
        name="title"
        defaultValue={storage?.title}
      />
    </form>
  );
};
