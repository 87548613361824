import 'twin.macro';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';
import { Text } from '../../Text/Text';
import { FC, useState } from 'react';
import { Button } from '../../Button/Button';
import { recordToArray } from '../util/object';

const cName = 'ResumeEducation';

type ResumeEducationProps = Record<string, string>;
type StoredProps = { degree: string; school: string; date: string };

export const ResumeEducation: FC<{
  componentName?: string;
  title?: string;
}> = ({ componentName = cName, title = 'Education' }) => {
  const { isEditing, storage } =
    useResumeComponentStorage<ResumeEducationProps>(componentName);

  if (isEditing) {
    return <Editor storage={storage} componentName={componentName} />;
  }

  return (
    <div>
      <Text variant="title">{title}</Text>
      <ul>
        {recordToArray<StoredProps>(storage).map((edu, index) => (
          <li tw="flex" key={index}>
            <Text>{[edu.degree, edu.school, edu.date].join(' - ')}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Editor: FC<{
  storage?: ResumeEducationProps;
  componentName: string;
}> = ({ storage, componentName }) => {
  const [educations, setEducations] = useState(
    recordToArray<StoredProps>(storage) || []
  );

  const addEducation = () => {
    setEducations([...educations, { degree: '', school: '', date: '' }]);
  };

  const removeEducation = (index: number) => {
    const newEducations = [...educations];
    newEducations.splice(index, 1);
    setEducations(newEducations);
  };

  const updateEducation = (
    index: number,
    key: keyof (typeof educations)[number],
    value: string
  ) => {
    const newEducations = [...educations];
    newEducations[index][key] = value;
    setEducations(newEducations);
  };

  return (
    <form name={componentName} tw="w-full">
      {educations.map((edu, index) => (
        <div key={index}>
          <input
            placeholder="Degree"
            type="text"
            name={`degree-${index}`}
            value={edu.degree}
            onChange={(e) => updateEducation(index, 'degree', e.target.value)}
          />
          <span tw="mx-2">-</span>
          <input
            placeholder="School Name"
            type="text"
            name={`school-${index}`}
            value={edu.school}
            onChange={(e) => updateEducation(index, 'school', e.target.value)}
          />
          <span tw="mx-2">-</span>

          <input
            placeholder="YYYY - YYYY"
            type="text"
            name={`date-${index}`}
            value={edu.date}
            onChange={(e) => updateEducation(index, 'date', e.target.value)}
          />
          <Button type="button" onClick={() => removeEducation(index)}>
            Remove
          </Button>
        </div>
      ))}

      <div>
        <Button type="button" onClick={addEducation}>
          Add Education
        </Button>
        <Button type="button" onClick={removeEducation}>
          Remove Education
        </Button>
      </div>
    </form>
  );
};
