import React, { FC } from 'react';
import tw, { TwStyle } from 'twin.macro';
import { Text } from '../Text/Text';

export type MenuItem = {
  title: string;
  items: {
    title: string;
    action?: () => void;
    isActive?: boolean;
    count?: number;
    url?: string;
  }[];
};

type MenuItemProps = {
  item: MenuItem;
  linkElement?: React.ElementType;
  css?: TwStyle[];
  onClick?: () => void;
};
const findActiveUrl = (url: string) => window?.location?.pathname === url;

export const MenuItem: FC<MenuItemProps> = ({
  item,
  linkElement,
  onClick,
  ...rest
}) => {
  return (
    <div tw="space-y-4" {...rest}>
      <Text variant="sub" tw="font-bold">
        {item.title}
      </Text>
      <ul tw="space-y-4">
        {item.items.map((subItem, index) => (
          <li
            key={index}
            tw="transition-all duration-300 cursor-pointer hover:(bg-blue-800 rounded-md py-1 px-2)"
            css={[
              (subItem.url && findActiveUrl(subItem.url)) || subItem.isActive
                ? tw`rounded-md bg-blue-600 py-1 px-2`
                : tw``,
            ]}
          >
            <Text
              onClick={onClick}
              tw="w-full flex"
              as={subItem.url ? linkElement || 'a' : 'button'}
              href={subItem.url}
              to={subItem.url}
            >
              {subItem.title}
              {subItem.count && (
                <span tw="ml-auto">
                  <div className="badge badge-info badge-md">
                    {subItem.count}
                  </div>
                </span>
              )}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};
