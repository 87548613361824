import { FC } from 'react';
import { Text } from '../Text/Text';
import 'twin.macro';

type TableProps = {
  head: (JSX.Element | string)[];
  rows: (JSX.Element | string | number | null | undefined)[][];
  bottomRow?: (JSX.Element | string)[];
};

export const Table: FC<TableProps> = ({ head, rows, bottomRow, ...rest }) => {
  return (
    <div className="overflow-x-auto w-full" {...rest}>
      <table className="table">
        {/* head */}
        <thead>
          <tr>
            {head.map((h, i) => (
              <Text as="th" key={i}>
                {h}
              </Text>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr className="hover" key={i}>
              {row.map((r, j) => (
                <Text as="td" key={j}>
                  {r}
                </Text>
              ))}
            </tr>
          ))}
          {bottomRow && (
            <tr tw="border-t-2 border-opacity-80 border-slate-200">
              {bottomRow.map((r, j) => (
                <Text as="td" key={j}>
                  {r}
                </Text>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
