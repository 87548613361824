import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';
import tw from 'twin.macro';
import { GitHubIcon, GlobeIcon, LinkedInIcon } from '../../Icons/Icons';
import { Text } from '../../Text/Text';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';

const ContainerStyle = tw`flex flex-col space-y-2`;
const SimpleInput = tw.input`rounded-md`;
const CustomText = tw(Text)`flex`;
type ResumeSocialsProps = Partial<{
  linkedin: string;
  github: string;
  website: string;
  phone: string;
  email: string;
}>;

const componentName = 'ResumeSocials';

export const ResumeSocials = () => {
  const { storage, isEditing } =
    useResumeComponentStorage<ResumeSocialsProps>(componentName);

  if (isEditing) {
    return <Editor storage={storage} />;
  }

  return (
    <div css={ContainerStyle}>
      {storage?.linkedin && (
        <CustomText as="a" target="_blank" href={storage?.linkedin}>
          <LinkedInIcon tw="w-4 mr-2" />
        </CustomText>
      )}
      {storage?.github && (
        <CustomText as="a" target="_blank" href={storage?.github}>
          <GitHubIcon tw="w-4 mr-2" />
        </CustomText>
      )}
      {storage?.website && (
        <CustomText as="a" target="_blank" href={storage?.website}>
          <GlobeIcon tw="w-4 mr-2" />
        </CustomText>
      )}
      {storage?.phone && (
        <CustomText as="a" href={`tel:${storage?.phone}`}>
          <PhoneIcon tw="w-4 mr-2" />
        </CustomText>
      )}
      {storage?.email && (
        <CustomText as="a" href={`mailto:${storage?.email}`}>
          <EnvelopeIcon tw="w-4 mr-2" />
        </CustomText>
      )}
    </div>
  );
};

const Editor: FC<{ storage: ResumeSocialsProps | undefined }> = ({
  storage,
}) => {
  return (
    <form name={componentName} css={ContainerStyle}>
      <label tw="flex space-x-2" htmlFor="linkedin">
        <LinkedInIcon tw="w-4" />{' '}
        <SimpleInput
          type="text"
          name="linkedin"
          defaultValue={storage?.linkedin}
          placeholder="Linkedin"
        />
      </label>
      <label tw="flex space-x-2" htmlFor="github">
        <GitHubIcon tw="w-4" />{' '}
        <SimpleInput
          type="text"
          name="github"
          defaultValue={storage?.github}
          placeholder="Github"
        />
      </label>
      <label tw="flex space-x-2" htmlFor="website">
        <GlobeIcon tw="w-4" />{' '}
        <SimpleInput
          type="text"
          name="website"
          defaultValue={storage?.website}
          placeholder="Website"
        />
      </label>
      <label tw="flex space-x-2" htmlFor="phone">
        <PhoneIcon tw="w-4" />{' '}
        <SimpleInput
          type="text"
          name="phone"
          defaultValue={storage?.phone}
          placeholder="Phone"
        />
      </label>
      <label tw="flex space-x-2" htmlFor="email">
        <EnvelopeIcon tw="w-4" />{' '}
        <SimpleInput
          type="text"
          name="email"
          defaultValue={storage?.email}
          placeholder="Email"
        />
      </label>
    </form>
  );
};
