import 'twin.macro';

import React, { FC, PropsWithChildren } from 'react';
import { PageLayout } from '../PageLayout/PageLayout';
import tw, { TwStyle } from 'twin.macro';
import { Section } from '../Layout/Layout';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';

const generateMockItems = (amount: number) =>
  Array.from({ length: amount }, (_, i) => ({
    name: `Item ${i}`,
    link: `#item-${i}`,
    action: () => console.log(`Item ${i} clicked`),
  }));

type MenuItem = {
  name: string;
  link?: string;
  action?: () => void;
};

export type MenuLayoutProps = PropsWithChildren<{
  menuItems: MenuItem[];
  activeItem?: string;
  LinkItem?: React.ElementType;
  sectionStyle?: TwStyle[];
  version?: string;
}>;

export const MenuLayout: FC<MenuLayoutProps> = ({
  menuItems = generateMockItems(10),
  activeItem,
  LinkItem,
  children,
  sectionStyle,
  version,
}) => {
  return (
    <PageLayout tw="grid-cols-12">
      <LeftSide>
        <ul tw="flex flex-col space-y-4 w-full">
          {version && (
            <li tw="w-full">
              <Text>v {version}</Text>
            </li>
          )}
          {menuItems.map((item) => (
            <li tw="w-full" key={item.name}>
              <Button
                tw="w-full"
                as={LinkItem || 'a'}
                href={item?.link}
                to={item?.link}
                onClick={item?.action}
                variant={activeItem === item.link ? 'success' : 'primary'}
              >
                {item.name}
              </Button>
            </li>
          ))}
        </ul>
      </LeftSide>
      <Section css={sectionStyle} tw="col-start-3 col-end-13 items-start">
        {children}
      </Section>
    </PageLayout>
  );
};

const LeftSide = tw.aside`col-start-1 col-end-2 bg-slate-800 rounded p-4 flex flex-col`;
