'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import {
  FC,
  MouseEvent as RMouseEvent,
  TouchEvent,
  useRef,
  useState,
} from 'react';
import tw from 'twin.macro';

type DiffProps = {
  imageLeft: string;
  imageRight: string;
};

export const Diff: FC<DiffProps> = ({ imageLeft, imageRight }) => {
  return (
    <div tw="diff aspect-[16/9] rounded-md hidden lg:grid">
      <div tw="diff-item-1">
        <img alt="daisy" src={imageLeft} />
      </div>
      <div tw="diff-item-2">
        <img alt="daisy" src={imageRight} />
      </div>
      <div tw="diff-resizer">
        <ResizeSVG />
      </div>
    </div>
  );
};

const diffImageContainerId = 'diff-image-container';
const diffImageRightId = 'diff-image-right';
const imageRightId = 'image-right';
const DiffImage = tw.img`aspect-[16/9] h-full w-full object-cover relative pointer-events-none`;
const DiffContainer = tw.div`aspect-[16/9] rounded-md flex flex-row flex-nowrap relative overflow-hidden lg:hidden`;

const changeElementMargin = (element: HTMLElement, x: number) => {
  element.style.marginLeft = `${x}px`;
};

const changeElementObjectPosition = (element: HTMLElement, x: number) => {
  element.style.objectPosition = `${x}px`;
};

const onTouchMove = (e: TouchEvent<HTMLButtonElement>) => {
  const movingX = e.touches[0].clientX;
  const container = document.getElementById(diffImageContainerId);
  const maxWidth = container?.offsetWidth || 0;
  const element = document.getElementById(diffImageRightId);
  const imageRight = document.getElementById(imageRightId);

  if (movingX < 10) return;
  if (movingX > maxWidth - 10) return;

  if (!element) return;
  changeElementMargin(element, movingX);
  if (!imageRight) return;
  changeElementObjectPosition(imageRight, Math.abs(movingX) * -1);
};

export const DiffCustom: FC<DiffProps> = ({ imageLeft, imageRight }) => {
  const [mouseDown, setMouseDown] = useState(false);
  const elementRight = useRef<HTMLButtonElement>(null);

  const onMouseMove = (e: RMouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!mouseDown) return;
    const movingX = e.movementX;
    const container = document.getElementById(diffImageContainerId);
    const maxWidth = container?.offsetWidth || 0;
    const element = document.getElementById(diffImageRightId);
    const imageRight = document.getElementById(imageRightId);

    if (movingX < 10) return;
    if (movingX > maxWidth - 10) return;

    if (!element) return;
    changeElementMargin(element, movingX);
    if (!imageRight) return;
    changeElementObjectPosition(imageRight, Math.abs(movingX) * -1);
  };

  return (
    <DiffContainer id={diffImageContainerId}>
      <DiffImage tw="absolute left-0 top-0" alt="daisy" src={imageLeft} />

      <div id={diffImageRightId} tw="relative ml-[50%] right-0 top-0 h-full">
        <div tw="absolute h-full z-10">
          <div tw="absolute left-0 h-full border border-amber-50" />
          <button
            ref={elementRight}
            onTouchMove={onTouchMove}
            onMouseMove={onMouseMove}
            onMouseDown={() => {
              console.log('mousedown');

              setMouseDown(true);
            }}
            onMouseUp={() => {
              console.log('mouseUp');
              setMouseDown(false);
            }}
            type="button"
            tw="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 bg-amber-50 p-2 rounded-full border border-amber-50"
          >
            <ResizeSVG />
          </button>
        </div>
      </div>
      <DiffImage
        id={imageRightId}
        alt="daisy"
        src={imageRight}
        tw="[object-position: calc(-50vw + 2rem) center]"
      />
    </DiffContainer>
  );
};

const ResizeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="size-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
    />
  </svg>
);
