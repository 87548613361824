/** @jsxImportSource @emotion/react */

import React, { ComponentProps, FC } from 'react';
import tw from 'twin.macro';
import { Input } from '../Input/Input';
import { Select } from '../Select/Select';
import { Text } from '../Text/Text';
import { Dropdown } from '../Dropdown/Dropdown';

const Bar = tw.nav`w-full flex py-2 border-b border-gray-600 z-10`;

type UserBarProps = {
  userName: string;
  defaultLocale?: string;
  localizations?: string[];
  onLocalizationChange?: (localization: string) => void;
  notificationsCount?: number;
  useSearch?: boolean;
  onSearch?: (search: string) => void;
  dropdownItems?: ComponentProps<typeof Dropdown>['dropdownItems'];
};

export const UserBar: FC<UserBarProps> = ({
  userName,
  defaultLocale = 'Select language',
  localizations,
  notificationsCount,
  useSearch,
  dropdownItems,
  onSearch,
  onLocalizationChange,
  ...rest
}) => {
  const initials = userName
    .split(' ')
    .map((n) => n[0])
    .join('');
  return (
    <Bar {...rest}>
      {useSearch && (
        <div tw="flex items-center mr-auto">
          <Input
            placeholder="Search..."
            onChange={(e) => onSearch?.(e.target.value)}
          />
        </div>
      )}
      <div tw="ml-auto flex items-center space-x-4">
        {localizations && (
          <Select
            defaultItem={defaultLocale}
            onChange={(e) => onLocalizationChange?.(e.target.value)}
            selectItems={localizations.map((l) => ({
              title: l,
              action: () => onLocalizationChange?.(l),
            }))}
          />
        )}
        <div tw="divider divider-horizontal"></div>
        <Dropdown dropdownItems={dropdownItems || []}>
          <Text>{userName}</Text>
          <Text
            variant="subTitle"
            tw="w-12 h-12 rounded-full bg-black flex items-center justify-center"
          >
            {initials}
          </Text>
        </Dropdown>
      </div>
    </Bar>
  );
};
