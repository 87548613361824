/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { FC } from 'react';

export type RatingProps = {
  rating: number;
};

export const Rating: FC<RatingProps> = ({ rating }) => {
  return (
    <div tw="rating rating-sm lg:rating-md">
      <input
        type="radio"
        name="rating-2"
        tw="mask mask-star-2 bg-orange-400"
        checked={rating === 1}
      />
      <input
        type="radio"
        name="rating-2"
        tw="mask mask-star-2 bg-orange-400"
        checked={rating === 2}
      />
      <input
        type="radio"
        name="rating-2"
        tw="mask mask-star-2 bg-orange-400"
        checked={rating === 3}
      />
      <input
        type="radio"
        name="rating-2"
        tw="mask mask-star-2 bg-orange-400"
        checked={rating === 4}
      />
      <input
        type="radio"
        name="rating-2"
        tw="mask mask-star-2 bg-orange-400"
        checked={rating === 5}
      />
    </div>
  );
};
