/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { FC, PropsWithChildren } from 'react';
import { Card } from '../Card/Card';
import { Text } from '../Text/Text';
import { Button } from '../Button/Button';
import { MinusIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

type CartItemProps = PropsWithChildren<{
  _id?: string | null;
  name?: string | null;
  price?: string | null;
  quantity?: string | null;
  image?: string | null;
  onRemove?: () => void;
  onIncrease?: () => void;
  onDecrease?: () => void;
}> &
  React.ComponentProps<typeof Card>;

export const CartItem: FC<CartItemProps> = ({
  name,
  price,
  quantity,
  image,
  total,
  onDecrease,
  onIncrease,
  onRemove,
  ...rest
}) => {
  return (
    <Card tw="relative w-full flex items-start" {...rest}>
      {onRemove && (
        <Button
          tw="absolute -left-4 -top-4 rounded-full"
          variant="danger"
          onClick={onRemove}
        >
          <TrashIcon tw="w-4 h-4" />
        </Button>
      )}
      <img
        src={image as string}
        alt={'cart-item-' + name}
        tw="w-16 h-16 rounded bg-orange-200"
      />
      <div tw="ml-4">
        <Text tw="font-bold">{name}</Text>
        <Text>{price}</Text>

        <Text>{total}</Text>
      </div>
      <div tw="flex items-center space-x-2 ml-auto">
        {onDecrease && (
          <Button variant="warning" onClick={onDecrease}>
            <MinusIcon tw="w-4 h-4" />
          </Button>
        )}
        <Text tw="w-full text-center" variant="subTitle">
          {quantity}
        </Text>
        {onIncrease && (
          <Button variant="success" onClick={onIncrease}>
            <PlusIcon tw="w-4 h-4" />
          </Button>
        )}
      </div>
    </Card>
  );
};
