import { localeEN, localeFR, localeNL, TLocales } from './locale';

export const geweigerd = 'geweigerd';
export const geaccepteerd = 'geaccepteerd';
export const afgerond = 'afgerond';
export const gereserveerd = 'gereserveerd';
export const inAfwachting = 'In afwachting';

export const statuses = {
  geweigerd,
  geaccepteerd,
  afgerond,
  gereserveerd,
  [inAfwachting]: inAfwachting,
} as const;

export type TStatus = (typeof statusList)[number];

export const statusList = Object.values(statuses);

export const translateStatus = (status: TStatus, locale: TLocales) => {
  switch (status) {
    case geweigerd:
      return statusTranslations[locale].geweigerd;
    case geaccepteerd:
      return statusTranslations[locale].geaccepteerd;
    case afgerond:
      return statusTranslations[locale].afgerond;
    case gereserveerd:
      return statusTranslations[locale].gereserveerd;
    case inAfwachting:
      return statusTranslations[locale][inAfwachting];
    default:
      return status;
  }
};

type TStatuses = Record<TStatus, string>;

const statusTranslations: Record<TLocales, TStatuses> = {
  [localeNL]: {
    geweigerd: 'Geweigerd',
    geaccepteerd: 'Geaccepteerd',
    afgerond: 'Afgerond',
    gereserveerd: 'Gereserveerd',
    [inAfwachting]: 'In afwachting',
  },
  [localeEN]: {
    geweigerd: 'Rejected',
    geaccepteerd: 'Accepted',
    afgerond: 'Completed',
    gereserveerd: 'Reserved',
    [inAfwachting]: 'Pending',
  },
  [localeFR]: {
    geweigerd: 'Refusé',
    geaccepteerd: 'Accepté',
    afgerond: 'Terminé',
    gereserveerd: 'Réservé',
    [inAfwachting]: 'En attente',
  },
};
