import React from 'react';
import { Text } from '../../Text/Text';
import { useResumeComponentStorage } from '../hooks/useResumeComponentStorage';
import 'twin.macro';
const componentName = 'ResumeAbout';

type ResumeAboutProps = Partial<{
  about: string;
}>;

export const ResumeAbout = () => {
  const { isEditing, storage } =
    useResumeComponentStorage<ResumeAboutProps>(componentName);

  if (isEditing) {
    return <Editor storage={storage} />;
  }

  return (
    <div>
      <Text>{storage?.about}</Text>
    </div>
  );
};

const Editor: React.FC<{ storage?: ResumeAboutProps }> = ({ storage }) => {
  return (
    <form name={componentName} tw="w-full">
      <Text
        tw="w-full h-full min-h-[300px]"
        as="textarea"
        placeholder="I am a full stack developer..."
        name="about"
        defaultValue={storage?.about}
      />
    </form>
  );
};
