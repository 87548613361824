import { FC, PropsWithChildren } from 'react';

type CollapseProps = {
  tabIndex?: number;
  open?: boolean;
  title?: string;
};

export const Collapse: FC<PropsWithChildren<CollapseProps>> = ({
  tabIndex = 0,
  title,
  children,
  ...rest
}) => {
  return (
    <details
      // tabIndex={tabIndex}
      className={`collapse collapse-arrow border border-base-300 bg-base-200`}
      {...rest}
    >
      <summary className="collapse-title text-xl font-medium">{title}</summary>
      <div className="collapse-content">{children}</div>
    </details>
  );
};
