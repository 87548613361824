/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { FC } from 'react';
import { Card } from '../Card/Card';
import { Rating } from '../Rating/Rating';
import { Text } from '../Text/Text';

export type ReviewProps = {
  rating: number;
  quote: string;
  person: string;
};

export const Review: FC<ReviewProps> = ({ rating, quote, person }) => {
  return (
    <Card tw="space-y-4 bg-white w-full">
      <div tw="flex w-full justify-between">
        <Text tw="opacity-60 w-8 h-8 flex items-center justify-center rounded-full bg-purple-500 text-white">
          {person}
        </Text>
        <Rating rating={rating} />
      </div>
      <Text tw="text-black text-left">"{quote}"</Text>
    </Card>
  );
};
