export const toCurrency = (price: number | string) => {
  const number = typeof price === 'string' ? parseInt(price) : price;
  if (isNaN(number)) throw new Error('Invalid number');

  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);
};

export const returnHighestAndLowest = (arr: number[]) => {
  if (arr.length === 1) return arr;
  const unique = [...new Set(arr)];
  const highest = Math.max(...unique);
  const lowest = Math.min(...unique);

  return [lowest, highest];
};
