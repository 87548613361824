import { useEffect, useState } from 'react';
import { resumeButtonId } from '../consts/consts';

export const useResumeComponentStorage = <T>(componentName: string) => {
  const [storage, setStorage] = useState<T>();
  const [isEditing, setIsEditing] = useState(false);
  const getStorage = () => {
    const data = localStorage.getItem(componentName);
    if (data) {
      setStorage(JSON.parse(data));
    }
  };

  const setStorageData = (data: T) => {
    localStorage.setItem(componentName, JSON.stringify(data));
    setStorage(data);
  };

  const toggleEditing = () => {
    const form = document.forms.namedItem(componentName);
    if (form) {
      const formData = new FormData(form);
      const data = form ? Object.fromEntries(formData.entries()) : null;
      console.log(data);
      if (data) {
        setStorageData(data as T);
      }
    }

    setIsEditing((prev) => !prev);
  };

  useEffect(() => {
    getStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentName]);

  useEffect(() => {
    const resumeButton = document.getElementById(resumeButtonId);
    if (!resumeButton) {
      console.error('No resume button found');
      return;
    }
    resumeButton?.addEventListener('click', toggleEditing);
    return () => {
      resumeButton?.removeEventListener('click', toggleEditing);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { storage, isEditing, toggleEditing, getStorage, setStorageData };
};
