export const localeNL = 'nl-NL';
export const localeEN = 'en-US';
export const locales = [localeNL, localeEN] as const;
export type TLocales = (typeof locales)[number];

export const addLocaleToPath = (pathname: string, locale: TLocales) => {
  const otherLocales = locales.filter((l) => l !== locale);
  if (!locales.includes(locale)) {
    return pathname;
  }
  if (pathname === '/') {
    return `/${locale}`;
  }
  // if locale is already in the pathname, return it
  if (pathname.includes(locale)) {
    return pathname;
  }
  // if different locale is already in the pathname, replace it
  if (otherLocales.some((l) => pathname.includes(l))) {
    return otherLocales.reduce(
      (acc, l) => acc.replace(`/${l}`, `/${locale}`),
      pathname
    );
  }
  // add locale to the pathname
  return `/${locale}${pathname}`;
};

export const findLocaleInUrlAndAddToPath = (
  pathname: string,
  nextPath: string
) => {
  const locale = locales.find((l) => pathname.includes(l));
  if (!locale) {
    return nextPath;
  }
  return addLocaleToPath(nextPath, locale);
};

export const validateLocale = (locale: string): TLocales => {
  if (locale === localeEN || locale === localeNL) {
    return locale;
  }
  return localeNL;
};
